import React from "react";
import Header from "./header";
import "styles/layout.scss";

const Layout = props => {
  return (
    <div className="container">
      <div className="container-headerContent">
        <Header />
        {props.children}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
