import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import "styles/header.scss";
import logo from "../../static/logo.png";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <header className="header-container">
      <Link to="/">
        <h1 className="header-title">
          <img
            src={logo}
            alt="logo"
            height="80"
            width="80"
            style={{ margin: 0 }}
          />

          <span className="header-title-text">
            {data.site.siteMetadata.title}
          </span>
        </h1>
      </Link>
      <nav>
        <ul className="nav-list">
          {/* <li><Link className="nav-list-link" activeClassName="nav-list-link-active" to="/">Home</Link></li> */}
          <li>
            <Link
              className="nav-list-link"
              activeClassName="nav-list-link-active"
              to="/blog/"
            >
              Blog
            </Link>
          </li>
          {/* <li><Link className="nav-list-link" activeClassName="nav-list-link-active"  to="/about">About</Link></li> */}
          <li>
            <Link
              className="nav-list-link"
              activeClassName="nav-list-link-active"
              to="/projects/"
            >
              Projects
            </Link>
          </li>
        </ul>
      </nav>
      <div className="nav-mobile">
        <Link to="/" activeClassName="nav-mobile-each">
          <span className="material-icons">home</span>
        </Link>
        <Link to="/blog/" activeClassName="nav-mobile-each">
          <span className="material-icons">class</span>
        </Link>
        <Link to="/projects/" activeClassName="nav-mobile-each">
          <span className="material-icons">work</span>
        </Link>
      </div>
    </header>
  );
};

export default Header;
